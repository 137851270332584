import { Menu } from 'antd';
import classnames from 'classnames';
import { useHistory } from 'react-router';

import { useStoreActions } from 'Store';
import { PATH_NAME } from 'Constants/PathName.Constant';
import { formatMessage } from 'Utils/formatMessage';
import LogoutModal from 'Components/LogoutModal';
import { useModalContext } from 'Context/Modal';
import { clearItems } from 'Utils/localStorage';

import classes from './RequesterHeader.module.scss';
import Dropdown from '../../Common/DropDown';
import { MenuItemProps } from '../../Common/types';

const RightHeader: React.FC = () => {
  const history = useHistory();

  const { updateModal, hideModal } = useModalContext();

  const logout = useStoreActions((actions) => actions.staffAuth.logout);

  const handleLogout = () => {
    clearItems();
    logout();
    history.push(PATH_NAME.STAFF_LOGIN);
    hideModal();
  };

  const showLogoutModal = () => {
    updateModal(<LogoutModal logout={handleLogout} />, {
      showTitle: true,
      width: 411,
      height: 'auto',
    });
  };

  const PROFILE_MENUS: MenuItemProps[] = [
    {
      title: 'nav_menu_change_password',
      key: 'change-password',
      type: 'normal',
      onClick: () => history.push(PATH_NAME.CHANGE_PASSWORD),
    },
    {
      title: 'nav_menu_logout',
      key: 'logout',
      type: 'danger',
      onClick: () => showLogoutModal()
    },
  ];

  const profileOverlay = (
    <Menu className={'menus_box'}>
      {PROFILE_MENUS.map((item) => (
        <Menu.Item
          className={classnames(
            'menus_box--item',
            item.type === 'danger' && 'danger'
          )}
          key={item.key}
          onClick={item.onClick}
        >
          {formatMessage(item.title)}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={classes['header__right']}>
      <div className={classes['header_info']}>
        {/* <div className={classes['info_avatar']}>
          <Image
            src={merchantInfo?.avatar}
            alt="apple_logo"
            height={40}
            width={40}
          />
        </div> */}
        <Dropdown overlay={profileOverlay} />
      </div>
    </div>
  );
};

export default RightHeader;
