import { Redirect, Route } from 'react-router-dom';

import ErrorBoundary from 'Components/ErrorBoundary';
import { PATH_NAME } from 'Constants/PathName.Constant';
import { useStoreState } from 'Store';
import { KEYS } from 'Constants/Common.Constant';
import { getItem } from 'Utils/localStorage';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const authToken = useStoreState((state) => state.staffAuth.token);
  const storageToken = getItem(KEYS.AUTH_TOKEN);
  const isAuthenticated = authToken || storageToken;

  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        isAuthenticated ? (
          <ErrorBoundary>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: PATH_NAME.STAFF_LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
