import classnames from 'classnames';

import { IIconCircleProps } from '../types';
import classes from './IconCircle.module.scss';
import BoxContent from './BoxContent.Component';

const IconBox: React.FC<IIconCircleProps> = ({
  icon,
  iconSrc,
  color = 'blue-light',
  size = 50,
  ratio = 0.5,
  className = '',
  type,
  disabled,
  onClick,
  iconStyle,
  iconClass,
  style,
}) => {
  const iconSize = ratio * size;

  if (type === 'button') {
    return (
      <div
        className={classnames(
          classes['btn_icon--box'],
          classes['icon-box-container'],
          `bg-${color}`,
          className,
          {
            [classes['disabled']]: disabled,
          }
        )}
        style={{
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
          ...style,
        }}
        onClick={() => {
          if (disabled) return;
          if (onClick) onClick();
        }}
      >
        <BoxContent
          icon={icon}
          iconStyle={iconStyle}
          iconClass={iconClass}
          iconSrc={iconSrc}
          iconSize={iconSize}
          width={iconSize}
          height={iconSize}
        />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        classes['icon-box-container'],
        `bg-${color}`,
        className
      )}
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...style,
      }}
    >
      <BoxContent
        icon={icon}
        iconStyle={iconStyle}
        iconClass={iconClass}
        iconSrc={iconSrc}
        iconSize={iconSize}
        width={iconSize}
        height={iconSize}
      />
    </div>
  );
};

export default IconBox;
