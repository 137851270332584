import { useRef } from 'react';

import { Card, Image, Input } from 'antd';
import moment from 'moment';

import { useStoreState } from 'Store';
import { formatMessage } from 'Utils/formatMessage';
import Copy from 'Assets/icons/copy.png';
import { copyToClipBoard } from 'Utils/common';

import classes from './CardBilling.module.scss';

const CardBilling: React.FC = () => {
  const ref = useRef<any>(null);

  const data = useStoreState((state) => state.invoice);

  return (
    <>
      <Card className={classes['card']}>
        <div className={classes['card__copy']}>
          <Image
            src={Copy}
            width={12}
            height={12}
            preview={false}
            onClick={() => copyToClipBoard(ref)}
          />

          <Input ref={ref} disabled id="copyValue" value={data.requestPayId} />
        </div>
      </Card>
      <Card className={classes['card']}>
        <div className={classes['card__billing']}>
          <p>{formatMessage('invoice.expire')}</p>
          <p>{moment(data.expiredAt).format('DD MMMM YYYY')}</p>
        </div>
        <div className={classes['card__billing']}>
          <p>{formatMessage('invoice.pay_to')}</p>
          <p>{data.payTo}</p>
        </div>
        <div className={classes['card__billing']}>
          <p>{formatMessage('invoice.pay_by')}</p>
          <p>{data.payBy}</p>
        </div>
        <div className={classes['card__billing']}>
          <p>{formatMessage('invoice.note')}</p>
          <p>{data.note}</p>
        </div>
        <div className={classes['card__billing']}>
          <p>{formatMessage('invoice.amount')}</p>
          <p>${data.amount}</p>
        </div>
        <div className={classes['card__billing']}>
          <p>{formatMessage('invoice.gst')}</p>
          <p>{data.gst ? 'GST' : 'No GST'}</p>
        </div>
        <div className={classes['card__billing']}>
          <p>{formatMessage('invoice.total')}</p>
          <p>${data.total}</p>
        </div>
      </Card>
    </>
  );
};

export default CardBilling;
