import { message } from 'antd';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import cloneDeep from 'lodash/cloneDeep';
import capitalize from 'lodash/capitalize';

export const DEFAULT_MESSAGE = 'Oops, something went wrong!';

const normalizeError = (errors: any) => {
  if (!errors) return DEFAULT_MESSAGE;
  if (!!errors.base) return errors.base;

  if (!!errors.message) return errors.message;

  if (isArray(errors)) {
    return errors[0]
      ? errors[0].base
        ? errors[0].base
        : errors[0]
      : DEFAULT_MESSAGE;
  }

  if (isObject(errors)) {
    const error = get(errors, 'response.data.errors', {});
    if (error.base) return error.base;

    const cloned: { [key: string]: any } = cloneDeep(errors);
    const messages = [];
    for (const key in errors) {
      const keyName = capitalize(key.split('_').join(' '));
      const errorMessage = isArray(cloned[key]) ? cloned[key][0] : cloned[key];
      messages.push(`${keyName} ${errorMessage}`);
    }

    return messages;
  }

  if (isString(errors)) return errors;
  return DEFAULT_MESSAGE;
};

export const showErrorMessage = (errors: any) => {
  if (isString(errors)) return message.error(errors);
  if (isArray(errors)) {
    return errors.forEach((error) => {
      message.error(error);
    });
  }

  if (isObject(errors)) {
    const mess = Object.values(errors);
    if (mess.length) return message.error(mess[0]);
  }

  return message.error(DEFAULT_MESSAGE);
};

export default normalizeError;
