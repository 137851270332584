export const HTTP_CODE = {
  InternalServerError: 500,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
};

export const KEYS = {
  TOKEN: 'token',
  AUTH_TOKEN: 'auth_token',
  SUB_DOMAIN: 'sub_domain',
};
