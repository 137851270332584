import './index.scss';

const NotFound = ({ showBtn }: any) => {
  return <div id="not_found" className="container"></div>;
};

NotFound.defaultProps = {
  showBtn: true,
};

export default NotFound;
