export const API = {
  staffLogin: '/staffs/sign_in',
  staffChangePassword: './staffs/reset_password',
  staffForgotPassword: 'staffs/request_forget_password',
  paymentRequests: '/payment_requests',
  createPaymentRequest: '/staffs/create_payment_request',
  payees: '/payees',
  enterEmail: '/merchant_sessions/enter_email',
  enterABN: '/merchant_sessions/enter_abn',
  enterPayId: '/merchant_sessions/enter_pay_id',
  enterFinalInfo: '/merchant_sessions/enter_final_information',
  enterPassword: '/merchant_sessions/sign_in',
  merchantProfile: '/merchants/info',
  invoice: '/payment_requests/view',
  checkStatus: '/payment_requests/public_status',
};
