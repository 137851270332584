import { Action, action, thunk, Thunk } from 'easy-peasy';

import {
  submitForgotPassword,
  submitPasswordChange,
  submitStaffLogin,
} from 'Services/staffAuth';
import { setItem } from 'Utils/localStorage';
import { KEYS } from 'Constants/Common.Constant';

export interface IAuthModel {
  token?: string;
  loading: boolean;
  subDomain: string;
}

export interface InPersonModel extends IAuthModel {
  setLoading: Action<InPersonModel, boolean>;
  setAccessToken: Action<InPersonModel, string>;
  logout: Action<InPersonModel>;
  submitStaffLogin: Thunk<InPersonModel, any>;
  submitPasswordChange: Thunk<InPersonModel, any>;
  submitForgetPassword: Thunk<InPersonModel, any>;
}

const initialState = {
  token: '',
  subDomain: '',
  loading: false,
};

const inPersonModel: InPersonModel = {
  ...initialState,

  setAccessToken: action((state: IAuthModel, payload): void => {
    state.token = payload;
  }),

  setLoading: action((state: IAuthModel, payload: boolean): void => {
    state.loading = payload;
  }),

  submitStaffLogin: thunk(
    async (actions, { email, password, fnCallback }: any, { getState }) => {
      const state: IAuthModel = getState();

      if (!state.loading) actions.setLoading(true);
      const response = await submitStaffLogin({
        password,
        email,
      });

      if (response.success) {
        setItem(KEYS.AUTH_TOKEN, response.data.authenticateToken);
        setItem(KEYS.SUB_DOMAIN, response.data.subDomain);

        actions.setAccessToken(response.data.authenticateToken);

        actions.setLoading(false);

        if (fnCallback) fnCallback();
      } else {
        actions.setLoading(false);
      }
    }
  ),

  submitPasswordChange: thunk(async (actions, payload: any, { getState }) => {
    const state: IAuthModel = getState();

    if (!state.loading) actions.setLoading(true);
    const response = await submitPasswordChange({
      ...payload,
    });
    if (response.success) {
      // setItem(KEYS.AUTH_TOKEN, response.data.authenticateToken);
      // setItem(KEYS.SUB_DOMAIN, response.data.subDomain);

      // actions.setAccessToken(response.data.authenticateToken);

      actions.setLoading(false);
    } else {
      actions.setLoading(false);
    }
    return response;
  }),

  submitForgetPassword: thunk(async (actions, payload: any, { getState }) => {
    const state: IAuthModel = getState();

    if (!state.loading) actions.setLoading(true);
    const response = await submitForgotPassword({
      ...payload,
    });

    if (response.success) {
      // setItem(KEYS.AUTH_TOKEN, response.data.authenticateToken);
      // setItem(KEYS.SUB_DOMAIN, response.data.subDomain);

      // actions.setAccessToken(response.data.authenticateToken);

      actions.setLoading(false);
    } else {
      actions.setLoading(false);
    }

    return response;
  }),

  logout: action((): any => {
    return initialState;
  }),
};

export default inPersonModel;
