import get from 'lodash/get';

import api from 'Utils/staffAxios';
import normalizeError, { showErrorMessage } from 'Utils/normalizeError';
import { API } from 'Constants/Service.Constant';

interface ICreatePaymentRequest {
  amount: string;
  gst: string;
  note?: string;
}

export const submitCreatePaymentRequest = async (
  params: ICreatePaymentRequest
) => {
  try {
    const response = await api.post(API.createPaymentRequest, params);
    const paymentRequestToken = get(response.data, 'payment_request_token', '');
    const id = get(response.data, 'id', '');
    const total = get(response.data, 'total', '');
    const feeRate = get(response.data, 'fee_rate', '');
    const gstAmount = get(response.data, 'gst_amount', '');
    const gst = get(response.data, 'gst', false);

    return {
      success: true,
      data: { paymentRequestToken, id, total, feeRate, gstAmount, gst },
      error: null,
    };
  } catch (errors) {
    showErrorMessage(errors);

    return {
      success: false,
      data: {
        paymentRequestToken: '',
        id: '',
        total: '',
        feeRate: '',
        gstAmount: '',
        gst: false,
      },
      error: normalizeError(errors),
    };
  }
};
