import {
  createStore,
  createTypedHooks,
  persist,
  PersistConfig,
} from 'easy-peasy';


import StaffAuth, { InPersonModel } from './StaffAuth';
import PaymentRequest, { PaymentRequestModel } from './PaymentRequest';
import Invoice, { InvoiceModel } from './Invoice';

const customPersist = (model: any, options?: PersistConfig<StoreModel>) =>
  persist(model, { ...options, storage: 'localStorage' });

export interface StoreModel {
  staffAuth: InPersonModel;
  paymentRequest: PaymentRequestModel;
  invoice: InvoiceModel;
}

const { useStoreActions, useStoreState, useStoreDispatch, useStore } =
  createTypedHooks<StoreModel>();

const storeModel: StoreModel = {
  staffAuth: customPersist(StaffAuth),
  paymentRequest: PaymentRequest,
  invoice: Invoice,
};

// window.requestIdleCallback = null;
const storeEnhancers: any[] = [];

// if (__DEV__) {
//   storeEnhancers = [...storeEnhancers];
// }

const store = createStore(storeModel, {
  name: 'CleverPay',
  enhancers: [...storeEnhancers],
});

export { useStoreActions, useStoreState, useStoreDispatch, useStore };

export default store;
