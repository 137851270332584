import { Result } from 'antd';
import { useHistory } from 'react-router';

import { formatMessage } from 'Utils/formatMessage';
import Button from 'Components/Common/Button';
import { PATH_NAME } from 'Constants/PathName.Constant';

import './index.scss';

const NoInvoice: React.FC = () => {
  const history = useHistory();
  const handleClick = () => {
    return history.push(PATH_NAME.REQUESTER);
  };

  return (
    <div id="not_found" className="container">
      <Result
        status="404"
        title={formatMessage('global.no.invoice')}
        extra={
          <Button type="primary" onClick={handleClick}>
            {formatMessage('btn_make_payment')}
          </Button>
        }
      />
    </div>
  );
};

export default NoInvoice;
