import { Form, Input, Button, Select, InputNumber } from 'antd';
import { Redirect, useHistory } from 'react-router';
import classnames from 'classnames';

import { useStoreState, useStoreActions } from 'Store';
import { formatMessage } from 'Utils/formatMessage';
// import { removeSpace } from 'Utils/common';

import { PATH_NAME } from 'Constants/PathName.Constant';
import classes from './Requester.module.scss';
import RequesterLayout from 'Components/Layouts/RequesterLayout';

const RequesterContainer: React.FC = () => {
  const history = useHistory();

  const [formRef] = Form.useForm();

  const { Option } = Select;

  const { TextArea } = Input;

  const loading = useStoreState((state) => state.paymentRequest.loading);

  const authToken = useStoreState((state) => state.staffAuth.token);

  const submitCreatePaymentRequest = useStoreActions(
    (action) => action.paymentRequest.submitCreatePaymentRequest
  );

  const onFinish = async (formData: any) => {
    await submitCreatePaymentRequest({
      amount: formData.amount,
      gst: formData.additional,
      note: formData.note,
      fnCallback,
    });
  };

  const formatAmountValue = (value: any) => {
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const parseAmountValue = (value: any) => {
    return Number(value.replace(/\$\s?|(,*)/g, ''));
  };

  const OnChangeAmount = (event: any) => {
    return formRef.setFieldsValue({ amount: event });
  };

  const fnCallback = () => {
    history.push(PATH_NAME.SHARE_QR);
  };

  if (!authToken) return <Redirect to="/staff-login" />;

  return (
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <Form
            form={formRef}
            labelCol={{ span: 24 }}
            className={classes['form']}
            onFinish={onFinish}
            initialValues={{
              additional: '1',
              amount: 0,
            }}
          >
            <Form.Item
              label="Amount"
              name="amount"
              className={classes['form__item']}
              rules={[
                {
                  required: true,
                  message: formatMessage('requester.amount'),
                },
                {
                  min: 1,
                  message: formatMessage('requester.amount.minimum'),
                  type: 'number',
                },
              ]}
            >
              <InputNumber
                className={classes['form__item-amount']}
                formatter={formatAmountValue}
                parser={parseAmountValue}
                onChange={OnChangeAmount}
              />
            </Form.Item>
            <Form.Item
              label="Additional"
              name="additional"
              className={classes['form__item']}
              rules={[
                {
                  required: true,
                  message: formatMessage('validate.gst_required'),
                },
              ]}
            >
              <Select className={classes['form__item-select-additional']}>
                <Option value="1">GST</Option>
                <Option value="0">No GST</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Note"
              name="note"
              className={classes['form__item']}
              rules={[
                {
                  required: true,
                  message: formatMessage('validate.note_required'),
                },
                {
                  min: 5,
                  message: formatMessage('validate.note_min_exceed'),
                },
                {
                  max: 50,
                  message: formatMessage('validate.note_max_exceed'),
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                className={classes['form__item-text-area']}
              />
            </Form.Item>

            <div className={classes['button-container']}>
              <Button
                htmlType="submit"
                type="primary"
                className={classnames(classes['button-next'])}
                loading={loading}
              >
                {formatMessage('btn_next')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default RequesterContainer;
