import { Button } from 'antd';
import classnames from 'classnames';

import IconCircle from 'Components/Common/IconCircle';
import { useModalContext } from 'Context/Modal';
import { formatMessage } from 'Utils/formatMessage';

import classes from './LogoutModal.module.scss';

interface ILogout {
    logout: () => void;
}

const LogoutModal: React.FC<ILogout>= (props) => {
  const { hideModal } = useModalContext();

  return (
    <div className={classes['content']}>
      <div className={classes['content-container']}>
        <div className={classes['icon-container']}>
          <IconCircle icon="👇🏼" color="blue-light" size={80} />
        </div>
        <div className={classes['title-container']}>
          <p className={classes['title']}>{formatMessage('logout_title')}</p>
        </div>
        <div className={classes['button-container']}>
          <Button className="button-back" onClick={hideModal}>
            {formatMessage('btn_no_logout')}
          </Button>
          <Button
            type="primary"
            className={classnames(classes['button-continue'], 'font-size-14')}
            onClick = {props.logout}
          >
            {formatMessage('btn_logout')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
