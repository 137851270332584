import { Action, action, thunk, Thunk } from 'easy-peasy';

import { getPaymentInvoice, getPaymentStatus } from 'Services/invoice';

export interface IInvoiceModel {
  amount: string;
  gst: boolean;
  note?: string;
  loading: boolean;
  requestPayId: string;
  feeRate: string;
  expiredAt: string;
  payTo: string;
  payBy: string;
  gstAmount: string;
  firstLoad: boolean;
  total: string;
  status: string;
  initData: boolean;
  paymentToken: string;
}

export interface InvoiceModel extends IInvoiceModel {
  savePaymentInvoice: Action<InvoiceModel, any>;
  setLoading: Action<InvoiceModel, boolean>;
  setInitData: Action<InvoiceModel, boolean>;
  setStatus: Action<InvoiceModel, string>;
  savePaymentToken: Action<InvoiceModel, string | null>;
  getPaymentInvoice: Thunk<InvoiceModel, any>;
  getPaymentStatus: Thunk<InvoiceModel, any>;
}

const initialState = {
  amount: '',
  gst: true,
  note: '',
  requestPayId: '',
  feeRate: '',
  expiredAt: '',
  payTo: '',
  payBy: '',
  gstAmount: '',
  loading: false,
  firstLoad: true,
  total: '',
  status: '',
  initData: false,
  paymentToken: '',
};

let timeOutId: any = 0;

const invoiceModel: InvoiceModel = {
  ...initialState,

  setLoading: action((state: IInvoiceModel, payload: boolean): void => {
    state.loading = payload;
  }),
  setInitData: action((state: IInvoiceModel, payload: boolean): void => {
    state.initData = payload;
  }),

  setStatus: action((state: IInvoiceModel, payload: string): void => {
    state.loading = false;
    state.status = payload;
  }),

  savePaymentToken: action((state: IInvoiceModel, payload: string): void => {
    state.paymentToken = payload;
  }),

  savePaymentInvoice: action(
    (
      state: IInvoiceModel,
      payload: {
        amount: string;
        gst: boolean;
        note: string;
        request_payid: string;
        fee_rate: string;
        expired_at: string;
        pay_to: string;
        pay_by: string;
        gst_amount: string;
        total: string;
      }
    ): void => {
      state.loading = false;
      state.firstLoad = false;
      state.amount = payload.amount;
      state.gst = payload.gst;
      state.note = payload.note;
      state.requestPayId = payload.request_payid;
      state.expiredAt = payload.expired_at;
      state.feeRate = payload.fee_rate;
      state.gstAmount = payload.gst_amount;
      state.payBy = payload.pay_by;
      state.payTo = payload.pay_to;
      state.total = payload.total;
    }
  ),

  getPaymentInvoice: thunk(async (actions, payload: any, { getState }) => {
    const state: IInvoiceModel = getState();

    if (!state.loading) actions.setLoading(true);
    const response = await getPaymentInvoice({
      token: payload,
    });
    if (response.success) {
      actions.savePaymentInvoice({
        amount: response.data.amount,
        gst: response.data.gst,
        note: response.data.note,
        request_payid: response.data.request_payid,
        fee_rate: response.data.fee_rate,
        expired_at: response.data.expired_at,
        pay_to: response.data.pay_to,
        pay_by: response.data.pay_by,
        gst_amount: response.data.gst_amount,
        total: response.data.total,
      });

      actions.setLoading(false);
    } else {
      actions.setLoading(false);
    }
  }),

  getPaymentStatus: thunk(async (action, payload: any, { getState }) => {
    clearTimeout(timeOutId);
    const state: IInvoiceModel = getState();
    if (!state.loading) action.setLoading(true);
    const response = await getPaymentStatus({ token: payload });
    if (response.success) {
      if (response.data.status === 'received') {
        action.setStatus(response.data.status);
      } else if (response.data.status === 'waiting') {
        timeOutId = setTimeout(() => {
          action.getPaymentStatus(payload);
        }, 30000);
        // action.setLoading(false);
      }
    } else {
      action.setStatus(response.data.status);
    }
  }),
};

export default invoiceModel;
