export const getDomain = () => {
  // if (__DEV__) {
  //   return process.env.LOCAL_HOST;
  // }
  return process.env.REACT_APP_DOMAIN;
  // return process.env.LOCAL_HOST;
};

interface Params {
  path: string;
  token: string | undefined;
}

export const generateQRCodeLink = (params: Params) => {
  // const domain = getDomain();
  const url = `${window.location.origin}/${params.path}/?token=${params.token}`;

  // scan qr
  // http://localhost:3000/invoice/?token=ABCDEF

  return url;
};
