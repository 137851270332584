import { LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';

const LoadingPage = () => {
  return (
    <Space
      size="middle"
      direction="vertical"
      align="center"
      className="justify-center h-full w-full"
    >
      <LoadingOutlined style={{ fontSize: 50 }} spin />
      CleverPay...
    </Space>
  );
};

export default LoadingPage;
