import { Form, Input, Button } from 'antd';
import { useHistory } from 'react-router';
import classnames from 'classnames';

import { useStoreState, useStoreActions } from 'Store';
import { removeSpace } from 'Utils/common';
import { formatMessage } from 'Utils/formatMessage';
import IconCircle from 'Components/Common/IconCircle';

import { PATH_NAME } from 'Constants/PathName.Constant';
import classes from './StaffLogin.module.scss';
import RequesterLayout from 'Components/Layouts/RequesterLayout';

const StaffLoginContainer: React.FC = () => {
  const history = useHistory();

  const loading = useStoreState((state) => state.staffAuth.loading);

  const submitStaffLogin = useStoreActions(
    (action) => action.staffAuth.submitStaffLogin
  );

  const [formRef] = Form.useForm();
  const onClickForgotPassword = (event: any) => {
    history.push(PATH_NAME.FORGOT_PASSWORD);
  };

  const onChangePassword = (event: any) => {
    const trimValue = removeSpace(event.target.value);
    formRef.setFieldsValue({ password: trimValue });
  };

  const onChangeEmail = (event: any) => {
    const trimValue = removeSpace(event.target.value);
    formRef.setFieldsValue({ email: trimValue });
  };

  const fnCallback = () => {
    history.push(PATH_NAME.REQUESTER);
  };

  const onFinish = (formData: any) => {
    submitStaffLogin({
      email: formData.email,
      password: formData.password,
      fnCallback,
    });
  };

  return (
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <div className={classes['icon-container']}>
            <IconCircle icon="CP" color="black" size={80} />
          </div>
          <div className={classes['title-container']}>
            <p className={classes['title']}>{formatMessage('login.title')}</p>
            <p className={classes['subtitle']}>
              {formatMessage('login.subtitle')}
            </p>
          </div>
          <Form
            labelCol={{ span: 24 }}
            className={classes['form']}
            form={formRef}
            onFinish={onFinish}
          >
            <Form.Item
              label="Your Email Address"
              name="email"
              className={classes['form__item']}
              rules={[
                {
                  type: 'email',
                  message: formatMessage('validate.email'),
                  required: true,
                },
              ]}
              validateTrigger="onBlur"
            >
              <Input
                className={classes['form__item-email']}
                onChange={onChangeEmail}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              className={classes['form__item']}
              rules={[
                { message: formatMessage('validate.password'), required: true },
                { message: formatMessage('validate.password.length'), min: 8 },
              ]}
              validateTrigger="onBlur"
            >
              <Input.Password
                className={classes['form__item-password']}
                onChange={onChangePassword}
              />
            </Form.Item>

            <div className={classes['forgot-password-container']}>
              <span>
                <a onClick={onClickForgotPassword}>
                  {formatMessage('forgot.password')}
                </a>
              </span>
            </div>

            <div className={classes['button-container']}>
              <Button
                htmlType="submit"
                type="primary"
                className={classnames(classes['button-continue'])}
                loading={loading}
              >
                {formatMessage('btn_login')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default StaffLoginContainer;
