import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import _get from 'lodash/get';

import { HTTP_CODE } from 'Constants/Common.Constant';
import { PATH_NAME } from 'Constants/PathName.Constant';
import { getItem, clearItems } from './localStorage';
import { KEYS } from 'Constants/Common.Constant';
import normalizeError from './normalizeError';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  withCredentials: false,
});

function handleBeforeCallApi() {
  // Add a request interceptor
  instance.interceptors.request.use(
    function (config: AxiosRequestConfig) {
      // Do something before request is sent
      const token = getItem(KEYS.AUTH_TOKEN);

      const configHeaders = config.headers;
      config.headers = {
        ...configHeaders,
        Authorization: token,
        SubDomain: `${process.env.SUBDOMAIN || ''}`,
      };

      return config;
    },
    function (error: AxiosError) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
}

function handleAfterCallApi() {
  // Add a response interceptor
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return response;
    },
    (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      return Promise.reject(handleDataError(error));
    }
  );
}

export function handleDataError(error: AxiosError) {
  let message;

  if (error.response) {
    if (error.response.status === HTTP_CODE.Unauthorized) {
      message =
        error.response.status === HTTP_CODE.Unauthorized
          ? 'Login failed'
          : 'Token expired. Please try again!';

      clearItems();
      window.location.href = PATH_NAME.ENTER_EMAIL;
    } else if (error.response.status === HTTP_CODE.Forbidden) {
      message = "You don't have permission to access";

      window.location.href = PATH_NAME.FORBIDDEN;
    } else if (error.response.status === HTTP_CODE.InternalServerError) {
      message = 'Internal Server Error';
    } else {
      const errors = _get(error.response.data, 'errors', {});
      const normalize = normalizeError(errors);
      message = normalize;
    }
  }

  if (!message || message.length < 1) {
    return { message: 'Oops, something went wrong' };
  }

  return { message };
}

handleBeforeCallApi();
handleAfterCallApi();

export default instance;
