import './index.scss';

const Forbidden = () => {
  return (
    <div id="forbidden" className="container">
      {/* <Result
        status={HTTP_CODE.Forbidden}
        title={HTTP_CODE.Forbidden}
        subTitle={t('not_authorized')}
        extra={
          <Button type="primary">
            <Link to="/">{t('back_home')}</Link>
          </Button>
        }
      /> */}
    </div>
  );
};

export default Forbidden;
