import { Redirect, Route } from 'react-router-dom';

import ErrorBoundary from 'Components/ErrorBoundary';
import { getItem } from 'Utils/localStorage';
import { useStoreState } from 'Store';
import { KEYS } from 'Constants/Common.Constant';
import { PATH_NAME } from 'Constants/PathName.Constant';

const PublicRoute = ({ component: Component, ...rest }: any) => {
  const token = useStoreState((state) => state.staffAuth.token);
  const storageToken = getItem(KEYS.TOKEN);
  const isAuthenticated = token || storageToken;

  return (
    <Route
      {...rest}
      render={({ location, ...props }) => {
        return !isAuthenticated ||
          location.pathname.includes(PATH_NAME.INVOICE) ? (
          <ErrorBoundary>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: PATH_NAME.REQUESTER,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
export default PublicRoute;
