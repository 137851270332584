import global from './global.json';
import payment from './payment.json';
import auth from './auth.json';
import message from './message.json';
import validate from './validate.json';
import requester from './requester.json';
import invoice from './invoice.json';

const locale = {
  ...global,
  ...payment,
  ...auth,
  ...message,
  ...validate,
  ...requester,
  ...invoice,
};

export default locale;
