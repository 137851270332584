import classnames from 'classnames';

import classes from './BoxContent.module.scss';
import Image from 'Components/Common/Image';

import { IBoxContentProps } from '../types';

const BoxContent: React.FC<IBoxContentProps> = ({
  icon,
  iconSrc,
  iconSize,
  width,
  height,
  iconStyle,
  iconClass,
}) => {
  if (iconSrc) {
    return (
      <Image
        src={iconSrc}
        alt={iconSrc}
        width={width}
        height={height}
        className={iconClass}
        style={{ ...iconStyle }}
      />
    );
  }

  return (
    <div
      style={{ fontSize: iconSize, ...iconStyle }}
      className={classnames(classes['box-content-text'], iconClass)}
    >
      {icon?.toUpperCase()}
    </div>
  );
};

export default BoxContent;
