export interface Bank {
  name: string;
  mobileApp: string[];
  desktopBrowser?: string[];
  notes: string[];
}

export const bankInstructions: Bank[] = [
  {
    name: 'ANZ',
    mobileApp: [
      'Tap the $ Pay icon on the Accounts tab',
      'Tap on the + to make a payment to a new PayID',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    desktopBrowser: [
      'Select the Payments menu, then Pay Anyone',
      'Select an account to make your payment from',
      'Select Add a new payee',
      'Choose Add a new payment type, select Email address',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    notes: [],
  },
  {
    name: 'Bendigo Bank',
    mobileApp: [
      'Select the Pay Anyone icon and add New Payee',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    desktopBrowser: [
      'Click on Move Money',
      'Click Pay Anyone',
      'Click New payee',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    notes: [],
  },
  {
    name: 'Commonwealth Bank',
    mobileApp: [
      'Select Pay someone from the homepage',
      'Select the + sign in the top right-hand corner',
      'From the menu of options, click more and then select Email address',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    notes: [],
  },
  {
    name: 'ING',
    mobileApp: [
      'Tap Pay, then Pay anyone',
      'Choose the account you wish to make the payment from',
      'Choose +New payee, then select PayID',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    desktopBrowser: [
      'Go to Transfer & pay, then Pay anyone',
      'Choose +New payee, then select PayID',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    notes: ['ING currently has a $1,000 daily limit on all PayID payments'],
  },
  {
    name: 'Macquarie Bank',
    mobileApp: [
      'Select the $ icon on the front screen',
      'Select Make a payment',
      'Add a new PayID by selecting Pay new',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    desktopBrowser: [
      'Select the $ icon on the side menu',
      'Select the account the payment should come from',
      'Add a new payee (PayID)',
      'Select Email and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    notes: [],
  },
  {
    name: 'NAB',
    mobileApp: [
      'Select the account you want to transfer from',
      'Tap Pay and then Pay Anyone',
      'Tap Mobile/PayID',
      'Select Email Address and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    desktopBrowser: [
      "Once you've logged in, click Funds Transfer and choose Pay to a PayID",
      'Under PayID type',
      'Select Email and enter %{payId}',
      'Select Next to proceed',
      'Choose the account you wish to pay from',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    notes: [],
  },
  {
    name: 'Suncorp',
    mobileApp: [
      'Select Pay/Transfer, followed by Pay Someone',
      'When you have selected your Account to pay from, tap Select Payee, then New Payee',
      'Select Email Address and enter %{payId}',
      'Enter %{amount}',
      'Review the transaction and finalise the payment',
    ],
    notes: [],
  },
  {
    name: 'Westpac',
    mobileApp: [
      'Tap the Pay icon',
      'Under From, select the account you want to make a payment from',
      'Under To, tap Choose a payee or biller',
      'Add a new PayID payee by selecting + Add',
      'Tap New PayID/Mobile payee',
      'Select Email Address and enter %{payId}',
      'Enter %{amount}',
    ],
    desktopBrowser: [
      'In the Payments tab, select Make a payment',
      'Add a new PayID payee by selecting Pay new payee',
      'Under I want to pay - choose Email Address and enter %{payId}',
      'Enter %{amount}',
    ],
    notes: [],
  },
  {
    name: "My bank isn't here",
    mobileApp: [
      'Open your mobile banking app',
      'Choose to pay anyone',
      'Use the pay by email option',
      'Enter or paste the PayID',
      'Validate the PayID is correct',
      'Enter the amount',
      'Pay',
    ],
    desktopBrowser: [
      'Open your online banking in a new tab',
      'Choose to pay anyone',
      'Use the pay by email option',
      'Enter or paste the PayID',
      'Validate the PayID is correct',
      'Enter the amount',
      'Pay',
    ],
    notes: [
      'Some banks will delay the payment for up to 24 hours. Rest assured that your payment will go through.',
    ],
  },
];
