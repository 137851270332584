import { Action, action, thunk, Thunk } from 'easy-peasy';

import { submitCreatePaymentRequest } from 'Services/requester';
import { setItem } from 'Utils/localStorage';
import { KEYS } from 'Constants/Common.Constant';

export interface IPaymentRequestModel {
  amount: string;
  gst: boolean;
  note?: string;
  paymentRequestToken?: string;
  id?: string;
  feeRate: string;
  total: string;
  gstAmount: string;
  loading: boolean;
}

export interface PaymentRequestModel extends IPaymentRequestModel {
  savePaymentRequest: Action<PaymentRequestModel, any>;
  setLoading: Action<PaymentRequestModel, boolean>;
  setAccessToken: Action<PaymentRequestModel, string>;
  submitCreatePaymentRequest: Thunk<PaymentRequestModel, any>;
}

const initialState = {
  amount: '',
  gst: true,
  note: '',
  paymentRequestToken: '',
  id: '',
  feeRate: '',
  total: '',
  gstAmount: '',
  loading: false,
};

const paymentRequestModel: PaymentRequestModel = {
  ...initialState,

  setAccessToken: action((state: IPaymentRequestModel, payload): void => {
    state.paymentRequestToken = payload;
  }),

  setLoading: action((state: IPaymentRequestModel, payload: boolean): void => {
    state.loading = payload;
  }),

  savePaymentRequest: action(
    (state: IPaymentRequestModel, payload: any): void => {
      state.loading = false;
      state.amount = payload.amount;
      state.gst = payload.gst;
      state.note = payload.note;
      state.paymentRequestToken = payload.paymentRequestToken;
      state.id = payload.id;
      state.feeRate = payload.feeRate;
      state.total = payload.total;
      state.gstAmount = payload.gstAmount;
    }
  ),

  submitCreatePaymentRequest: thunk(
    async (actions, { amount, gst, note, fnCallback }: any, { getState }) => {
      const state: IPaymentRequestModel = getState();

      if (!state.loading) actions.setLoading(true);
      const response = await submitCreatePaymentRequest({
        amount,
        gst,
        note,
      });

      if (response.success) {
        setItem(KEYS.TOKEN, response.data.paymentRequestToken);

        actions.savePaymentRequest({
          amount,
          note,
          ...response.data,
        });

        if (fnCallback) fnCallback();
      } else {
        actions.setLoading(false);
      }
    }
  ),
};

export default paymentRequestModel;
