import { useStoreState } from 'Store';
import { formatMessage } from 'Utils/formatMessage';
import IconCircle from 'Components/Common/IconCircle';
import RequesterLayout from 'Components/Layouts/RequesterLayout';

import classes from './SuccessStatus.module.scss';

const SuccessStatus: React.FC = () => {
  const data = useStoreState((state) => state.invoice);

  return (
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <div className={classes['icon-container']}>
            <IconCircle icon="💸" color="green-light" size={80} />
          </div>
          <div className={classes['title-container']}>
            <p className={classes['title']}>
              {formatMessage('status.success.title')}
            </p>
            <p className={classes['subtitle']}>
              <span className="text-2xl leading-10">
                {formatMessage('status.success.subtitle')}
                <span className={classes['status-success-subtitle']}>
                  {' '}
                  ${data.total}
                </span>{' '}
                <br /> to{' '}
                <span className={classes['status-success-subtitle']}>
                  {data.payTo}
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default SuccessStatus;
