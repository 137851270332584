// import classnames from 'classnames';

import Image from 'Components/Common/Image';
import { useStoreState } from 'Store';

import classes from './RequesterHeader.module.scss';
import logo from 'Assets/svg/logo-navbar-header.svg';
import RightHeader from './RightHeader.Component';

const RequesterHeader: React.FC = () => {
  const isAuth = useStoreState((states) => states.staffAuth.token);

  return (
    <div className={classes['header']}>
      <div className={classes['header__left']}>
        <div className={classes['logo']}>
          <Image src={logo} alt="logo" width={107} height={32} />
        </div>
      </div>
      <div className={classes['header__right']}>
        {isAuth && <RightHeader />}
      </div>
    </div>
  );
};

export default RequesterHeader;
