import { Form, Input, Button } from 'antd';
import { useHistory } from 'react-router';

import { formatMessage } from 'Utils/formatMessage';
//import { removeSpace } from 'Utils/common';
//import AuthenticationLayout from 'Components/Layouts/AuthLayout';

import classes from './ForgotPassword.module.scss';
import { PATH_NAME } from 'Constants/PathName.Constant';
//import imageRegisterSuccess from 'Assets/images/authentication/register-successfully-background.png';
import IconCircle from 'Components/Common/IconCircle';
import { useStoreActions } from 'Store';
import { useModalContext } from 'Context/Modal';
import ForgotSuccess from './ForgotSuccess.Component';
import ForgotFailed from './ForgotFailed.Component';
import RequesterLayout from 'Components/Layouts/RequesterLayout';

const ForgotPasswordContainer: React.FC = () => {
  const history = useHistory();
  const { showLoading, updateModal } = useModalContext();

  const submitForgetPassword = useStoreActions(
    (action) => action.staffAuth.submitForgetPassword
  );

  const fnCallbackSuccess = () => {
    history.push(PATH_NAME.STAFF_LOGIN);
  };

  const fnCallbackFailled = () => {
    //history.push(PATH_NAME.STAFF_LOGIN);
  };

  const onFinish = async (formData: any) => {
    showLoading();
    const respond = await submitForgetPassword({ ...formData });
    if (respond.success) {
      updateModal(
        <ForgotSuccess params={{ fnCallback: fnCallbackSuccess }} />,
        {
          showTitle: false,
          width: 400,
          height: 400,
        }
      );
    } else {
      updateModal(<ForgotFailed params={{ fnCallback: fnCallbackFailled }} />, {
        showTitle: true,
        width: 400,
        height: 400,
      });
    }
  };

  return (
    // <AuthenticationLayout
    //   button={<Button>{formatMessage('btn_individuals')}</Button>}
    //   background={imageRegisterSuccess}
    // >
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <div className={classes['icon-container']}>
            <IconCircle icon="ED" color="black" size={80} />
          </div>
          <div className={classes['title-container']}>
            <p className={classes['title']}>
              {formatMessage('forgot.password.title')}
            </p>
            <p className={classes['subtitle']}>
              {formatMessage('forgot.password.subtitle')}
            </p>
          </div>
          <Form
            labelCol={{ span: 24 }}
            className={classes['form']}
            onFinish={onFinish}
          >
            <Form.Item
              label="Your Email Address"
              name="email"
              className={classes['form__item']}
              rules={[
                {
                  type: 'email',
                  message: formatMessage('validate.email'),
                  required: true,
                },
              ]}
              validateTrigger="onBlur"

            >
              <Input
                className={classes['form__item-email']}

                //onChange={onChangeEmail}
              />
            </Form.Item>
            <div className={classes['button-container']}>
              <Button
                className="button-back"
                onClick={() => history.push(PATH_NAME.STAFF_LOGIN)}
              >
                {formatMessage('btn_back')}
              </Button>
              <Button type="primary" htmlType="submit" className="button-next">
                {formatMessage('btn_recover')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default ForgotPasswordContainer;
