import { ConfigProvider } from 'antd';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { useStoreRehydrated } from 'easy-peasy';

import { PATH_NAME } from 'Constants/PathName.Constant';
import {
  NotFound,
  ForgotPassword,
  ChangePassword,
  Requester,
  StaffLogin,
  ShareQR,
  Invoice,
  Forbidden,
} from 'Pages';
import LoadingPage from 'Components/Common/LoadingPage';

import PublicRoute from './Public.Route';
import PrivateRoute from './Private.Route';

const Routes = () => {
  const isRehydrated = useStoreRehydrated();
  // const signInToken = useStoreState((state) => state.auth.signInToken);

  if (!isRehydrated) {
    return <LoadingPage />;
  }

  return (
    <ConfigProvider>
      <Router>
        <Switch>
          <Redirect exact from={'/'} to={PATH_NAME.REQUESTER} />

          {/* Public Route */}
          <PublicRoute path={PATH_NAME.STAFF_LOGIN} component={StaffLogin} />
          <PublicRoute path={PATH_NAME.INVOICE} component={Invoice} />
          <PublicRoute
            path={PATH_NAME.FORGOT_PASSWORD}
            component={ForgotPassword}
          />

          {/* Private Route */}

          <PrivateRoute
            path={PATH_NAME.CHANGE_PASSWORD}
            component={ChangePassword}
          />

          <PrivateRoute path={PATH_NAME.REQUESTER} component={Requester} />
          <PrivateRoute path={PATH_NAME.SHARE_QR} component={ShareQR} />

          {/* Other */}
          <PublicRoute component={NotFound} />
          <PublicRoute component={Forbidden} />
        </Switch>
      </Router>
    </ConfigProvider>
  );
};

export default Routes;
