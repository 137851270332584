import { StoreProvider } from 'easy-peasy';

import { ModalProvider } from 'Context/Modal';
import store from 'Store';
import 'Utils/i18n';

import Router from './Router';
import './Styles/globals.scss';

const App = () => (
  <StoreProvider store={store}>
    <ModalProvider>
      <Router />
    </ModalProvider>
  </StoreProvider>
);

export default App;
