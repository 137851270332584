import { useEffect } from 'react';

import { useStoreActions, useStoreState } from 'Store';
import { getUrlParam } from 'Utils/common';
import WaitingStatus from '../WaitingStatus';
import SuccessStatus from '../SuccessStatus';
import ExpiredStatus from '../ExpiredStatus';

const InPersonStatusContainer: React.FC = () => {
  const status = useStoreState((state) => state.invoice.status);
  const checkStatus = useStoreActions(
    (actions) => actions.invoice.getPaymentStatus
  );

  const token = getUrlParam('token');

  useEffect(() => {
    checkStatus(token);
  }, [checkStatus, token]);

  if (status === 'received') {
    return <SuccessStatus />;
  } else if (status === 'expired') {
    return <ExpiredStatus />;
  }
  return <WaitingStatus />;
};

export default InPersonStatusContainer;
