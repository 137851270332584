import * as React from 'react';
import { Image as AntImage, ImageProps } from 'antd';

import cleverLogo from 'Assets/icons/clever-logo.png';

const Image = React.memo((props: ImageProps) => {
  const src = props.src || cleverLogo;

  return <AntImage {...props} src={src} preview={false} />;
});

export default Image;
