import { Layout } from 'antd';
import Image from 'Components/Common/Image';
// import { useLocation } from 'react-router-dom';
import RequesterHeader from '../RequesterHeader';
import { RequesterLayoutProps } from '../types';
import logoNavBar from 'Assets/svg/logo-navbar-header.svg';
import { formatMessage } from 'Utils/formatMessage';

import classes from './RequesterLayout.module.scss';

const { Content, Header } = Layout;

const RequesterLayout: React.FC<RequesterLayoutProps> = ({ children }) => {
  // const location = useLocation();

  // const currentPath =
  //   '/' + location.pathname.split('/').filter((path: string) => path)[0];

  return (
    <Layout className={classes['layout-container']}>
      <Layout className={classes['layout-side']}>
        <Header className={`${classes['layout-header']}`}>
          <RequesterHeader />
        </Header>
        <Content className={classes['layout-content']}>{children}</Content>
        <div className="flex gap-3 items-center justify-center pb-3 pt-3">
          <p className="font-semibold text-primary">
            {formatMessage('nav_menu_powered_by')}
          </p>
          <a href="https://helloclever.co">
            <Image
              src={logoNavBar}
              alt="logo-navbar-header"
              width={100}
              height={32}
            />
          </a>
        </div>
      </Layout>
    </Layout>
  );
};

export default RequesterLayout;
