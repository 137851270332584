import QRCodeStyling from 'qr-code-styling';
import logo from 'Assets/images/logo_icon.png';

export const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  image: logo,
  dotsOptions: {
    color: 'black',
    type: 'dots',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
  },
  cornersSquareOptions: {
    color: '#3e64ff',
  },
  cornersDotOptions: {
    color: '#00F9FF',
  },
  qrOptions: {
    errorCorrectionLevel: 'L',
  },
});
