import { message } from 'antd';
import { MutableRefObject } from 'react';
export const DEFAULT_FORMAT_DATE = 'DD/MM/YY';

export const formatCurrency = (value: number) => {
  const formatted = Intl.NumberFormat('en-IN').format(value);
  return `$${formatted}`;
};

export const removeSpace = (value: string) => {
  return value.replace(/\s/g, '');
};

export const getShortName = (name: string) => {
  if (!name?.length) return '';

  const _nameArr = name.split(' ');
  const length = _nameArr.length;
  const shortName =
    length === 1 ? _nameArr[0][0] : _nameArr[0][0] + _nameArr[length - 1][0];

  return shortName;
};

// export const copyToClipBoard = (value: string) => {
//   /* Get the text field */
//   const copyText = document.getElementById(value) as HTMLInputElement;

//   copyText.select();
//   copyText.setSelectionRange(0, 99999); /* For mobile devices */

//   /* Copy the text inside the text field */
//   navigator.clipboard.writeText(copyText.value);

//   message.success(`Copied ${copyText.value}`);
// };

export const copyToClipBoard = async (ref:MutableRefObject<any>) => {
  if (ref && ref.current) {
    const value = ref.current?.state?.value || '';
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(value);
      return message.success(`Copied ${value}`);
    } else {
      await document.execCommand('copy', true, value);
      return message.success(`Copied ${value}`);
    }
  }
};

export const getUrlParam = (name: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(name);
};

