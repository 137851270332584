export const PATH_NAME = {
  // PUBLIC
  LOGIN: '/login',
  FORBIDDEN: '/forbidden',
  REGISTER: '/register',
  ENTER_EMAIL: '/enter-email',
  ENTER_PASSWORD: '/enter-password',
  ENTER_ABN: '/enter-abn',
  ENTER_PAYID: '/enter-payid',
  ENTER_FINAL_INFO: '/enter-final-info',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  STAFF_LOGIN: '/staff-login',
  INVOICE: '/invoice',
  STATUS: '/status',

  // private
  DASHBOARD: '/dashboard',
  ITEMS: '/items',
  PAYMENTS: '/payments',
  ACCOUNTS: '/accounts',
  FIND: '/find',
  SUPPORT: '/support',
  DEVELOPER: '/developer',
  PROFILE: '/profile',
  REQUESTER: '/requester',
  SHARE_QR: '/share-qr',
};
