import { Form, Input, Button } from 'antd';
import { useHistory } from 'react-router';

import { removeSpace } from 'Utils/common';
import { formatMessage } from 'Utils/formatMessage';

import { useModalContext } from 'Context/Modal';

import { PATH_NAME } from 'Constants/PathName.Constant';
//import AuthenticationLayout from 'Components/Layouts/AuthLayout';
import classes from './ChangePassword.module.scss';
//import imageRegisterSuccess from 'Assets/images/authentication/register-successfully-background.png';
import IconCircle from 'Components/Common/IconCircle';
import { useStoreActions } from 'Store';
import { useStoreState } from 'Store';
// import { KEYS } from 'Constants/Common.Constant';
// import { getItem } from 'Utils/localStorage';
import ChangeSuccess from './ChangeSuccess.Component';
import ChangeFailed from './ChangeFailed.Component';
import RequesterLayout from 'Components/Layouts/RequesterLayout';

const ChangePasswordContainer: React.FC = () => {
  const history = useHistory();

  const [formRef] = Form.useForm();

  // const authToken = useStoreState((state) => state.staffAuth.token);
  // const storageToken = getItem(KEYS.AUTH_TOKEN);
  // const token = authToken || storageToken;

  const loading = useStoreState((state) => state.staffAuth.loading);

  const { showLoading, updateModal } = useModalContext();

  const submitPasswordChange = useStoreActions(
    (action) => action.staffAuth.submitPasswordChange
  );

  const onChangePassword = (event: any) => {
    const trimValue = removeSpace(event.target.value);
    formRef.setFieldsValue({ newPassword: trimValue });
  };

  const onVerifyPassword = (event: any) => {
    const trimValue = removeSpace(event.target.value);
    formRef.setFieldsValue({ verifyPassword: trimValue });
  };

  const fnCallbackSuccess = () => {
    history.push(PATH_NAME.STAFF_LOGIN);
  };

  const fnCallbackFailled = () => {
    //history.push(PATH_NAME.REQUESTER);
  };

  //get token form URL
  const url = window.location;
  const token = new URLSearchParams(url.search).get('token');

  const onFinish = async (formData: any) => {
    showLoading();
    const respond = await submitPasswordChange({
      password: formData.newPassword,
      token: token,
    });
    if (respond.success) {
      updateModal(
        <ChangeSuccess params={{ fnCallback: fnCallbackSuccess }} />,
        {
          showTitle: false,
          width: 400,
          height: 400,
        }
      );
    } else {
      updateModal(<ChangeFailed params={{ fnCallback: fnCallbackFailled }} />, {
        showTitle: true,
        width: 400,
        height: 400,
      });
    }
  };
  return (
    // <AuthenticationLayout
    //   button={<Button>{formatMessage('btn_individuals')}</Button>}
    //   background={imageRegisterSuccess}
    // >
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <div className={classes['icon-container']}>
            <IconCircle icon="ED" color="black" size={80} />
          </div>
          <div className={classes['title-container']}>
            <p className={classes['title']}>
              {formatMessage('change.password.title')}
            </p>
            <p className={classes['subtitle']}>
              {formatMessage('change.password.subtitle')}
            </p>
          </div>

          <Form
            labelCol={{ span: 24 }}
            className={classes['form']}
            onFinish={onFinish}
            form={formRef}
          >
            <Form.Item
              label={formatMessage('change.new.password')}
              name="newPassword"
              className={classes['form__item']}
              hasFeedback
              rules={[
                {
                  message: formatMessage('validate.password'),
                  required: true,
                  whitespace: false,
                },
                { message: formatMessage('validate.password.length'), min: 8 },
              ]}
              validateTrigger="onBlur"
            >
              <Input.Password
                className={classes['form__item-password']}
                onChange={onChangePassword}
              />
            </Form.Item>
            <Form.Item
              label={formatMessage('verify.new.password')}
              name="verifyPassword"
              className={classes['form__item']}
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: formatMessage('validate.password'),
                },
                { message: formatMessage('validate.password.length'), min: 8 },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(formatMessage('change.password.match'))
                    );
                  },
                }),
              ]}
              validateTrigger="onBlur"
            >
              <Input.Password
                className={classes['form__item-password']}
                onChange={onVerifyPassword}
              />
            </Form.Item>

            <div className={classes['button-container']}>
              <Button
                type="primary"
                className="button-login"
                htmlType="submit"
                loading={loading}
              >
                {formatMessage('btn_setup_new_password')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default ChangePasswordContainer;
