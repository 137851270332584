import { useModalContext } from 'Context/Modal';
import { formatMessage } from 'Utils/formatMessage';
import Button from 'Components/Common/Button';
import IconCircle from 'Components/Common/IconCircle';

import classes from './StatusModal.module.scss';

const ChangeSuccess: React.FC<{ params?: any }> = ({ params }) => {
  const { hideModal } = useModalContext();

  const { fnCallback } = params;

  const onButtonClick = () => {
    hideModal();
    if(fnCallback) fnCallback();
    fnCallback();
  };

  return (
    <div className={classes['content-container']}>
      <div className={classes['inner-container']}>
        <IconCircle
          icon="🎊"
          size={80}
          ratio={0.5}
          style={{ marginBottom: '1.5rem' }}
        />

        <h5 className={classes['title']}>
          {formatMessage('in_person.change_password.success_title')}
        </h5>
        <h5 className={classes['subtitle']}>
          {formatMessage('in_person.change_password.success_subtitle')}
        </h5>
      </div>

      <Button
        type="primary"
        className={classes['positive-button']}
        onClick={onButtonClick}
      >
        {formatMessage('global.got_it')}
      </Button>
    </div>
  );
};

export default ChangeSuccess;
