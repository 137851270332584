import React from 'react';
import { Link, RouteProps } from 'react-router-dom';

import Button from 'Components/Common/Button';
import './ErrorBoundary.module.scss';

// getSnapshotBeforeUpdate, componentDidCatch and getDerivedStateFromError
// There are no Hook equivalents for these methods yet, but they will be added soon.

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<RouteProps, IState> {
  constructor(props: RouteProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    // console.log("error: ", error);
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // Catch errors in any components below and re-render with error message
  //   // console.log("errors: ", error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div id="error-boundary" className="eb-container">
          <Button type="primary">
            <Link to="/">Back</Link>
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
