import { useState, useImperativeHandle } from 'react';
import { Modal, Spin } from 'antd';
import classnames from 'classnames';

import { ContextModalProps, ContextModalHeaderProps } from '../types';

import Image from 'Components/Common/Image';
import classes from './Modal.module.scss';
import closeIcon from 'Assets/icons/icon_close.svg';

const ModalHeader: React.FC<ContextModalHeaderProps> = ({
  title,
  hideModal,
}) => {
  return (
    <div
      className={classnames(classes['modal_header'], {
        [classes['modal_header_has_title']]: !!title,
      })}
    >
      {title && <p className={classes['modal_header--title']}>{title}</p>}
      <div className="cursor-pointer" onClick={hideModal}>
        <Image src={closeIcon} width={32} height={32} alt="icon_close" />
      </div>
    </div>
  );
};

interface IState {
  loading: boolean;
  visible: boolean;
  content: null | React.ReactNode;
  title: string;
  showTitle: boolean;
  width: string | number;
  height: string | number;
}

const ModalComponent: React.FC<ContextModalProps> = ({ modalRef }) => {
  const [state, setState] = useState<IState>({
    loading: true,
    visible: false,
    content: null,
    title: '',
    showTitle: false,
    width: 480,
    height: 480,
  });

  const showModal = () => {
    setState((prevState) => ({ ...prevState, visible: true }));
  };

  const hideModal = () => {
    if (state.loading) return;
    setState((prevState) => ({
      ...prevState,
      visible: false,
      content: null,
      loading: true,
      width: 480,
      height: 480,
    }));
  };

  useImperativeHandle(modalRef, () => ({
    show: () => showModal(),
    hide: () => hideModal(),
    update: (content: any, options: ContextModalProps['options']) => {
      setState((prevState) => ({
        ...prevState,
        visible: true,
        loading: false,
        content,
        title: options?.title || '',
        showTitle: options?.showTitle || false,
        width: options?.width || 480,
        height: options?.height || 480,
      }));
    },
    showLoading: () =>
      setState((prevState) => ({
        ...prevState,
        loading: true,
        width: 300,
        height: 300,
      })),
    hideLoading: () =>
      setState((prevState) => ({ ...prevState, loading: false })),
  }));

  return (
    <Modal
      wrapClassName={classnames(classes['modal_box'])}
      title={
        state.showTitle && !state.loading ? (
          <ModalHeader title={state.title} hideModal={hideModal} />
        ) : null
      }
      visible={state.visible}
      centered
      destroyOnClose
      closable={false}
      onCancel={hideModal}
      footer={null}
      width={state.width}
      style={{ height: state.height }}
    >
      {state.loading ? (
        <div className={classes['loading-container']}>
          <Spin size="large" />
        </div>
      ) : (
        state.content
      )}
    </Modal>
  );
};

export default ModalComponent;
