import React from 'react';
import { Dropdown } from 'antd';

import Image from 'Components/Common/Image';

import { DropdownComponentProps } from '../types';

import ArrowDownIcon from 'Assets/svg/arrow_down.svg';
import classes from './Dropdown.module.scss';

const DropdownComponent: React.FC<DropdownComponentProps> = ({
  icon,
  placement,
  overlay,
  ...rest
}) => {
  return (
    <Dropdown
      className={classes['dropdown_box']}
      overlay={overlay}
      trigger={['click']}
      placement={placement}
      {...rest}
    >
      {icon ? (
        icon
      ) : (
        <Image src={ArrowDownIcon} width={12} height={7} alt="arrow" />
      )}
    </Dropdown>
  );
};
export default DropdownComponent;
