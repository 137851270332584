import classNames from 'classnames';

import { formatMessage } from 'Utils/formatMessage';
import IconCircle from 'Components/Common/IconCircle';

import RequesterLayout from 'Components/Layouts/RequesterLayout';

import classes from './WaitingStatus.module.scss';

const WaitingStatus: React.FC = () => {
  return (
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <div className={classes['icon-container']}>
            <IconCircle icon="🚀" color="blue-lighter" size={80} />
          </div>
          <div className={classes['title-container']}>
            <p className={classes['title']}>
              {formatMessage('status.loading.title')}
            </p>
            <p className={classes['subtitle']}>
              {formatMessage('status.loading.subtitle')}
            </p>
          </div>
          <div className={classes['waiting-container']}>
            <div className={classNames(classes['item'], classes['first'])} />
            <div className={classNames(classes['item'], classes['second'])} />
            <div className={classNames(classes['item'], classes['third'])} />
            <div className={classNames(classes['item'], classes['fourth'])} />
          </div>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default WaitingStatus;
