import { useEffect } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { useStoreState, useStoreActions } from 'Store';
import { formatMessage } from 'Utils/formatMessage';
import IconCircle from 'Components/Common/IconCircle';
import RequesterLayout from 'Components/Layouts/RequesterLayout';
import { getUrlParam } from 'Utils/common';
import LoadingPage from 'Components/Common/LoadingPage';

import NoInvoice from './NoInvoice';
import SelectBank from './SelectBank';
import CheckStatus from './CheckStatus';
import classes from './Invoice.module.scss';
import CardBilling from './CardBilling';

const InvoiceContainer: React.FC = () => {
  const loading = useStoreState((state) => state.invoice.loading);
  const firstLoad = useStoreState((state) => state.invoice.firstLoad);
  const paymentToken = useStoreState((state) => state.invoice.paymentToken);
  const initedData = useStoreState((state) => state.invoice.initData);
  const getInvoice = useStoreActions(
    (action) => action.invoice.getPaymentInvoice
  );
  const setLoading = useStoreActions((action) => action.invoice.setLoading);
  const setInitData = useStoreActions((action) => action.invoice.setInitData);
  const saveToken = useStoreActions(
    (actions) => actions.invoice.savePaymentToken
  );

  const token = getUrlParam('token');

  useEffect(() => {
    if (firstLoad) {
      saveToken(token);
      getInvoice(token);
    }
  }, [firstLoad, getInvoice, token, saveToken]);

  const onPay = () => {
    setLoading(true);
    setInitData(true);
  };

  if (!paymentToken) {
    return <NoInvoice />;
  } else if (loading && firstLoad) {
    return <LoadingPage />;
  }

  if (initedData) {
    return <CheckStatus />;
  }

  return (
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <div className={classes['icon-container']}>
            <IconCircle icon="🤳🏼" color="blue-lighter" size={80} />
          </div>
          <div className={classes['title-container']}>
            <p className={classes['title']}>{formatMessage('invoice.title')}</p>
            <p className={classes['subtitle']}>
              {formatMessage('invoice.subtitle')}
            </p>
          </div>
          <CardBilling />
          <SelectBank />
          <div className={classes['button-container']}>
            <Button
              type="primary"
              className={classnames(classes['button-next'])}
              loading={loading}
              onClick={onPay}
            >
              {formatMessage('btn_pay')}
            </Button>
          </div>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default InvoiceContainer;
