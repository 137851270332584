import get from 'lodash/get';

import api from 'Utils/staffAxios';
import normalizeError, { showErrorMessage } from 'Utils/normalizeError';
import { API } from 'Constants/Service.Constant';

interface IStaffLogin {
  email: string;
  password: string;
}

export const submitStaffLogin = async (params: IStaffLogin) => {
  try {
    const response = await api.post(API.staffLogin, params);
    const authenticateToken = get(response.data, 'authenticate_token', []);
    const subDomain = get(response.data, 'subdomain', '');
    return {
      success: true,
      data: { authenticateToken, subDomain },
      error: null,
    };
  } catch (errors) {
    showErrorMessage(errors);

    return {
      success: false,
      data: { authenticateToken: '', subDomain: '' },
      error: normalizeError(errors),
    };
  }
};

export const submitPasswordChange = async (params: IStaffLogin) => {
  console.log('params in API', params);
  try {
    await api.post(API.staffChangePassword, params);
    // console.log('respond', response)
    // const authenticateToken = get(response.data, 'authenticate_token', []);
    // const subDomain = get(response.data, 'subdomain', '');
    return {
      success: true,
      //data: { authenticateToken, subDomain },
      error: null,
    };
  } catch (errors) {
    showErrorMessage(errors);

    return {
      success: false,
      //data: { authenticateToken: '', subDomain: '' },
      error: normalizeError(errors),
    };
  }
};

export const submitForgotPassword = async (params: IStaffLogin) => {
  console.log('params in API', params);
  try {
    await api.post(API.staffForgotPassword, params);
    // const authenticateToken = get(response.data, 'authenticate_token', []);
    // const subDomain = get(response.data, 'subdomain', '');
    return {
      success: true,
      //data: { authenticateToken, subDomain },
      error: null,
    };
  } catch (errors) {
    showErrorMessage(errors);

    return {
      success: false,
      //data: { authenticateToken: '', subDomain: '' },
      error: normalizeError(errors),
    };
  }
};
