import { Button, ButtonProps } from 'antd';

const ButtonComponent: React.FC<ButtonProps> = ({
  children,
  ...restprops
}) => {
  return <Button {...restprops}>{children}</Button>;
};

export default ButtonComponent;
