import api from 'Utils/staffAxios';
import normalizeError, { showErrorMessage } from 'Utils/normalizeError';
import { API } from 'Constants/Service.Constant';

interface IViewInvoiceRequest {
  token: string | undefined;
}

export const getPaymentInvoice = async ({ token }: IViewInvoiceRequest) => {
  try {
    const response = await api.get(API.invoice, { params: { token } });
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    showErrorMessage(errors);

    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};

export const getPaymentStatus = async ({ token }: IViewInvoiceRequest) => {
  try {
    const response = await api.get(API.checkStatus, { params: { token } });
    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (errors) {
    showErrorMessage(errors);

    return {
      success: false,
      data: {},
      error: normalizeError(errors),
    };
  }
};
