import { Form, Select } from 'antd';
import { useMemo, useState } from 'react';

import { formatMessage } from 'Utils/formatMessage';
import { bankInstructions } from 'Utils/bankInstructions';
import { useStoreState } from 'Store';

import classes from './index.module.scss';

const { Option } = Select;

const SelectBank: React.FC = () => {
  const [formRef] = Form.useForm();

  const [bankName, setBankName] = useState('Commonwealth Bank');
  const currentBank = useMemo(() => {
    return bankInstructions.find((bank) => {
      return bank.name === bankName;
    });
  }, [bankName]);
  const data = useStoreState((state) => state.invoice);

  const onSelect = (bankName: string) => {
    setBankName(bankName);
  };
  return (
    <>
      <Form
        form={formRef}
        labelCol={{ span: 24 }}
        className={classes['form']}
        initialValues={{
          bank: 'Commonwealth Bank',
        }}
      >
        <Form.Item
          label="Choose Your Bank"
          name="bank"
          rules={[
            { required: true, message: formatMessage('requester.amount') },
          ]}
        >
          <Select
            onSelect={onSelect}
            className={classes['form__item-select-bank']}
          >
            {bankInstructions.map((bank) => {
              return (
                <Option key={bank.name} value={bank.name}>
                  {bank.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
      <div className={classes['recommend-container']}>
        <div className={classes['recommend']}>
          <p>{formatMessage('recommend.mobile')}</p>
          <div className={classes['recommend-mobile']}>
            <ul>
              {currentBank?.mobileApp?.map((value, index) => {
                return (
                  <li className="mb-4" key={index.toString()}>
                    {value
                      .replace('%{payId}', data.requestPayId)
                      .replace('%{amount}', `$${data.total}`)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {currentBank?.desktopBrowser && (
          <div className={classes['recommend']}>
            <p>{formatMessage('recommend.desktop')}</p>
            <div className={classes['recommend-desktop']}>
              <ul>
                {currentBank?.desktopBrowser?.map((value, index) => {
                  return (
                    <li className="mb-4" key={index.toString()}>
                      {value
                        .replace('%{payId}', data.requestPayId)
                        .replace('%{amount}', `$${data.total}`)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectBank;
